<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-avatar>
      <div
        class="text-xs-center"
      >
        <v-avatar
          :color="formattedItem.avatarColor"
        >
          <v-icon
            dark
            small
          >
            {{ formattedItem.avatar }}
          </v-icon>
        </v-avatar>
      </div>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        v-html="formattedItem.title"
      />
      <v-list-item-subtitle
        v-html="formattedItem.subtitle"
      />
      <v-list-item-subtitle>
        <v-chip
          v-if="formattedItem.tiempo_trabajado.manodeobrafacturable"
          label
          small
          color="green"
          dark
          class="mt-1 ml-1"
        >
          Tiempo facturable
        </v-chip>
        <v-chip
          v-else
          label
          small
          color="red"
          dark
          class="mt-1 ml-1"
        >
          Tiempo no facturable
        </v-chip>
        <v-chip
          v-if="formattedItem.tiempo_trabajado.kilometros_facturables"
          label
          small
          color="green"
          dark
          class="mt-1 ml-1"
        >
          Desplazamiento facturable
        </v-chip>
        <v-chip
          v-else
          label
          small
          color="red"
          dark
          class="mt-1 ml-1"
        >
          Desplazamiento no facturable
        </v-chip>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action
      v-if="!readonly"
    >
      <v-btn
        v-if="hasPermBorrar"
        icon
        @click.stop="clickDelete"
      >
        <v-icon>{{ $vuetify.icons.values.delete }}</v-icon>
      </v-btn>
    </v-list-item-action>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import { TIEMPO_TRABAJADO } from '@/utils/consts'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    hasPermBorrar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = `${this.$options.filters.shortDateTime(item.tiempo_trabajado.finicio)} - ${item.empleado.nombre_y_apellido}`
      item.subtitle = this.$options.filters.humanizeDuration(item.tiempoTrabajado)
      if (item.tiempo_trabajado.es_desplazamiento) {
        item.avatar = this.$vuetify.icons.values.desplazamiento
        item.avatarColor = TIEMPO_TRABAJADO.colores.desplazamiento
      } else {
        item.avatar = this.$vuetify.icons.values.manoDeObra
        item.avatarColor = TIEMPO_TRABAJADO.colores.manoDeObra
      }
      return item
    }
  },
  methods: {
    clickDelete () {
      this.$emit('click-delete', { item: this.item, index: this.index })
    },
  },
}
</script>
